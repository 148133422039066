import { window } from "global";
import React from "react";
import classnames from "classnames";
import { useGlobal } from "reactn";
import { useHistory, useLocation, Link } from "react-router-dom";
import { useQueryString } from "hooks/general-hooks";
import { buildQueryParams, numberWithCommas } from "lib/general-helper";

const LeaderboardPagination = (props) => {
  const { firstEntryRanking, lastEntryRanking, totalCount, currentPage, hidePagination = false } = props;
  const [windowBreakpoint] = useGlobal("responsive");
  const history = useHistory();
  const location = useLocation();
  const queryParams = useQueryString();

  const goToPage = (page) => {
    if (currentPage === page) return;

    const search = { ...queryParams, page };
    delete search.user;

    window && window.scrollTo(0, 0);
    history.push({
      pathname: location.pathname,
      search: buildQueryParams(search),
    });
  };

  const isMobile = ["MOBILE_MEDIUM", "MOBILE_SMALL"].includes(windowBreakpoint);
  const lastPage = Math.floor(totalCount / 100) + 1;
  const maxPaginationBound = currentPage > 9999 ? 2 : 3;

  const shouldRenderPage = (page) => {
    if (isMobile && currentPage !== page) return false;

    return (
      currentPage === page ||
      currentPage < 10 ||
      (page < currentPage && page + maxPaginationBound >= currentPage) ||
      (page > currentPage && page - maxPaginationBound <= currentPage)
    );
  };

  const buildPages = () => {
    const maxPages = 9;
    const pages = Array(maxPages)
      .fill(null)
      .map((el, index) => {
        return currentPage <= 4 ? index + 1 : currentPage + (index - maxPaginationBound);
      });

    return pages.map((page) => {
      if (!shouldRenderPage(page)) return null;
      const isCurrentPage = currentPage === page;
      return (
        <div
          key={page}
          onClick={() => goToPage(page)}
          className={classnames("page-link", { "page-link_is-current": isCurrentPage })}
        >
          {page}
        </div>
      );
    });
  };

  if (hidePagination) {
    return null;
  }

  return (
    <div className="leaderboard_pagination">
      <div className="pagination-nav">
        <div className="skip-to-container skip-to-container_left">
          {currentPage > 1000 && (
            <div className="skip-to" onClick={() => goToPage(currentPage - 100)}>
              Prev 100
            </div>
          )}
          {currentPage !== 1 && (
            <div className="skip-to" onClick={() => goToPage(currentPage - 1)}>
              Prev
            </div>
          )}
        </div>
        <div className="pagination-nav_pages">{buildPages()}</div>
        <div className="skip-to-container skip-to-container_right">
          {currentPage !== lastPage && (
            <div className="skip-to" onClick={() => goToPage(currentPage + 1)}>
              Next
            </div>
          )}
          {currentPage !== lastPage && currentPage > 1000 && (
            <div className="skip-to" onClick={() => goToPage(currentPage + 100 > lastPage ? lastPage : currentPage + 100)}>
              Next 100
            </div>
          )}
        </div>
      </div>
      <div className="total-rows">
        <div className="current-rows">
          <span>{numberWithCommas(firstEntryRanking)}</span>
          <span className="dash"> - </span>
          <span>{numberWithCommas(lastEntryRanking)}</span>
        </div>
        <div className="out-of">
          <span>{numberWithCommas(totalCount)}</span> <span className="label">Total Summoners</span>
        </div>
      </div>
    </div>
  );
};

export default LeaderboardPagination;
