import classnames from "classnames";
import { Link } from "react-router-dom";
import MediaQuery from "components/MediaQuery";
import { getRiotAssetsContext, getS13RankUrl } from "@outplayed/riot-assets";
import { numberWithCommas } from "@ugg/shared/utils/math";
import { getTierColor, getProBuildRankName } from "@ugg/shared/utils/rank-helpers";
import { REGION_LABEL_MAP } from "../../../Champions/ChampionsPage/RegionOptions";
import { LeaderboardPlayerPlus } from "@ugg/shared/interfaces/leaderboards.interface";

interface ProfileIconContainerProps {
  playerData: LeaderboardPlayerPlus;
  hideLevel?: boolean;
  small?: boolean;
}

const ProfileIconContainer = (props: ProfileIconContainerProps) => {
  const { getProfileIconImg } = getRiotAssetsContext();
  const { playerData, hideLevel, small } = props;
  const { summonerLevel, tier, iconId } = playerData || {};
  const tierColor = getTierColor(tier);

  return (
    <div
      className={classnames("profile-icon-border-container", { "profile-icon-border-container_small": small })}
      style={{ borderColor: tierColor }}
    >
      {!hideLevel && summonerLevel && (
        <div className="level-header" style={{ borderColor: tierColor }}>
          {summonerLevel}
        </div>
      )}
      <div className="profile-icon-border">
        {!hideLevel && summonerLevel && <div className="border-notch" />}
        <img className="profile-icon-image" src={getProfileIconImg(iconId || 1)} />
      </div>
    </div>
  );
};

interface RankDisplayProps {
  tier: string;
  rank: string;
  lp: number;
}

const RankDisplay = (props: RankDisplayProps) => {
  const { tier, rank, lp } = props;

  return (
    <div className="rank-display">
      <img className="rank-img" src={getS13RankUrl(tier, true)} />
      <span>
        <strong style={{ color: getTierColor(tier) }}>
          <MediaQuery min="DESKTOP_SMALL" max="DESKTOP_LARGE" renderNullOnFail>
            {() => getProBuildRankName(tier, rank)}
          </MediaQuery>
          <MediaQuery min="MOBILE_SMALL" max="TABLET" renderNullOnFail>
            {() => getProBuildRankName(tier, rank, true)}
          </MediaQuery>
        </strong>
        <span className="backslash"> / </span>
        <span>{numberWithCommas(lp || 0)} LP</span>
      </span>
    </div>
  );
};

interface WinRateBarProps {
  playerData: LeaderboardPlayerPlus;
  isMobile?: boolean;
}

const WinRateBar = (props: WinRateBarProps) => {
  const { playerData, isMobile } = props;

  return (
    <div className="wr-with-bar">
      <div className="wr-text">
        <span className="wr-text_win-rate" style={{ color: playerData.winRateColor }}>
          {playerData.winRate}%
        </span>
        <span className="backslash"> / </span>
        <span className="wr-text_games">
          {numberWithCommas(playerData.totalGamesPlayed)} {isMobile ? "g" : "games"}
        </span>
      </div>
      <div className="wr-bar">
        <div style={{ height: "100%", width: `${playerData.winRate}%`, backgroundColor: playerData.winRateColor }} />
      </div>
    </div>
  );
};

interface LeaderboardTopFiveProps {
  data: LeaderboardPlayerPlus[];
  topPlayerMostPlayedChamp: number;
  showRegion?: boolean;
}

const LeaderboardTopFive = (props: LeaderboardTopFiveProps) => {
  const { getChampionSplashImg } = getRiotAssetsContext();
  const { data, topPlayerMostPlayedChamp, showRegion } = props;

  const topPlayer = data[0];
  const remainingPlayers = data.slice(1);

  return (
    <div className="leaderboard_top-five">
      <div className="top-five_ranking top-five_ranking__1">
        <div className="champion-splash-bg">
          <img src={getChampionSplashImg(topPlayerMostPlayedChamp)} />
        </div>
        <div className="ranking-info-container">
          <div className="ranking-label ranking-label_1">
            1<div className="label-underline" />
          </div>
          <ProfileIconContainer playerData={topPlayer} />
          <div className="ranking-info">
            <div className="ranking-user-row">
              {showRegion && topPlayer.regionId && REGION_LABEL_MAP[topPlayer.regionId] && (
                <div className="region-box">{REGION_LABEL_MAP[topPlayer.regionId]}</div>
              )}
              <Link to={topPlayer.profileURL} className="summoner-name">
                {topPlayer.riotUserName}
              </Link>
            </div>

            <div className="ranking-data-row">
              <RankDisplay tier={topPlayer.tier} rank={topPlayer.rank} lp={topPlayer.lp} />
              <WinRateBar playerData={topPlayer} />
            </div>
          </div>
        </div>
        <div className="ranking-info-container_mobile">
          <div className="ranking-header">
            <div className="ranking-label ranking-label_1">
              1<div className="label-underline" />
            </div>
            <ProfileIconContainer playerData={topPlayer} />
            {showRegion && topPlayer.regionId && REGION_LABEL_MAP[topPlayer.regionId] && (
              <div className="region-box">{REGION_LABEL_MAP[topPlayer.regionId]}</div>
            )}
            <Link to={topPlayer.profileURL} className="summoner-name">
              {topPlayer.riotUserName}
            </Link>
          </div>
          <div className="ranking-data-row">
            <RankDisplay tier={topPlayer.tier} rank={topPlayer.rank} lp={topPlayer.lp} />
            <WinRateBar playerData={topPlayer} />
          </div>
        </div>
      </div>
      {remainingPlayers.map((player, index) => {
        return (
          <div key={index} className={`top-five_ranking top-five_ranking__other top-five_ranking__${index + 2}`}>
            <div className="ranking-info-container">
              <div className="ranking-header">
                <div className={`ranking-label ranking-label_${index + 2}`}>{index + 2}</div>
                <div className="ranking-player">
                  <ProfileIconContainer playerData={player} hideLevel small />
                  <div className="ranking-user-column">
                    <Link to={player.profileURL} className="summoner-name">
                      {player.riotUserName}
                    </Link>
                    {showRegion && player.regionId && REGION_LABEL_MAP[player.regionId] && (
                      <div className="region-box">{REGION_LABEL_MAP[player.regionId]}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="ranking-data-row flex-center">
                <RankDisplay tier={player.tier} rank={player.rank} lp={player.lp} />
              </div>
              <div className="ranking-data-row flex-center">
                <WinRateBar playerData={player} />
              </div>
            </div>
            <div className="ranking-info-container_mobile">
              <div className="player">
                <div className={`ranking-label ranking-label_${index + 2}`}>{index + 2}</div>
                <div className="ranking-player">
                  <ProfileIconContainer playerData={player} hideLevel small />
                  <Link to={player.profileURL} className="summoner-name">
                    {player.riotUserName}
                  </Link>
                </div>
              </div>
              <RankDisplay tier={player.tier} rank={player.rank} lp={player.lp} />
              <WinRateBar playerData={player} isMobile />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default LeaderboardTopFive;
