import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { REGION_OPTIONS } from "components/Leaderboards/leaderboard-filters";
import MediaQuery from "components/MediaQuery";
import DefaultSelect from "components/DefaultSelect";
import { getBlockImg, getImg } from "lib/region_helper";
import { useQueryString } from "hooks/general-hooks";
import { buildQueryParams } from "lib/general-helper";
import ReactSVG from "react-svg";

const LeaderboardRegionFilter = (props) => {
  const { className, value, isMobile, options } = props;
  const history = useHistory();
  const location = useLocation();
  const queryParams = useQueryString();
  const { region } = queryParams;

  const getLabel = (options, value) => {
    const foundOption = options.find((option) => option.value === value);
    return (foundOption && foundOption.label) || "";
  };

  const onChange = (selected) => {
    const search = { ...queryParams };
    delete search.page;
    delete search.user;

    history.push({
      pathname: location.pathname,
      search: buildQueryParams({ ...search, region: selected.value }),
    });
  };

  const formatOptionLabel = ({ value, label }) => (
    <div className={`region-option`} key={value}>
      {value === "world" ? (
        <ReactSVG wrapperClassName="region-img-wrapper" className={`region-img flag-img`} path={getImg(value)} />
      ) : (
        <img src={getBlockImg(value)} />
      )}
      <span style={{ marginLeft: "10px" }}>{label}</span>
    </div>
  );

  return (
    <DefaultSelect
      title={"Change Region"}
      group={"region"}
      className={`filter-select filter-select_leaderboard-region ${className}`}
      isMobile={isMobile}
      formatOptionLabel={formatOptionLabel}
      options={options ? options : REGION_OPTIONS}
      onChange={onChange}
      value={value}
      width={100}
      isSearchable={false}
    />
  );
};

export default LeaderboardRegionFilter;
