export const REGION_OPTIONS = [
  { value: "na1", fullLabel: "North America", label: "NA" },
  { value: "euw1", fullLabel: "Europe West", label: "EUW" },
  { value: "eun1", fullLabel: "Europe Nordic & East", label: "EUN" },
  { value: "kr", fullLabel: "Korea", label: "KR" },
  { value: "br1", fullLabel: "Brazil", label: "BR" },
  { value: "jp1", fullLabel: "Japan", label: "JP" },
  { value: "ru", fullLabel: "Russia", label: "RU" },
  { value: "oc1", fullLabel: "Oceania", label: "OCE" },
  { value: "tr1", fullLabel: "Turkey", label: "TR" },
  { value: "la1", fullLabel: "Latin America North", label: "LAN" },
  { value: "la2", fullLabel: "Latin America South", label: "LAS" },
  { value: "ph2", fullLabel: "Philippines", label: "PH" },
  { value: "sg2", fullLabel: "Singapore", label: "SG" },
  { value: "th2", fullLabel: "Thailand", label: "TH" },
  { value: "tw2", fullLabel: "Taiwan", label: "TW" },
  { value: "vn2", fullLabel: "Vietnam", label: "VN" },
  { value: "me1", fullLabel: "Middle East", label: "ME" },
];
