import { useEffect, useMemo, useRef } from "react";
import classnames from "classnames";
import { useGlobal } from "reactn";
import { Link } from "react-router-dom";
import LeaderboardPagination from "./LeaderboardPagination";
import { useTable, useSortBy, usePagination, Column, Row } from "react-table";
import { useQueryString } from "@ugg/shared/hooks/use-query-string";
import { getRiotAssetsContext, getS13RankUrl } from "@outplayed/riot-assets";
import { numberWithCommas } from "@ugg/shared/utils/math";
import { getTierColor, getProBuildRankName } from "@ugg/shared/utils/rank-helpers";
import { REGION_LABEL_MAP } from "../../../Champions/ChampionsPage/RegionOptions";
import { splitRiotId } from "@ugg/shared/utils/riot-id-helpers";
import { LeaderboardPlayerPlus } from "@ugg/shared/interfaces/leaderboards.interface";

const columnStyle = (column: any) => ({
  minWidth: column.totalMinWidth,
  maxWidth: column.totalMaxWidth,
  flex: `1 1 100%`,
});

const TableSummonerRow = ({
  rowIndex,
  row,
  isSearchedSummoner,
}: {
  rowIndex: number;
  row: Row<LeaderboardPlayerPlus>;
  isSearchedSummoner: boolean;
}) => {
  const rowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isSearchedSummoner && rowRef.current) {
      setTimeout(() => {
        rowRef.current?.scrollIntoView({ block: "center" });
      }, 0);
    }
  }, []);

  return (
    <div ref={rowRef} className={classnames("rt-tr-group", { "summoner-highlighted": isSearchedSummoner })}>
      <div {...row.getRowProps({ className: "rt-tr" })}>
        {row.cells.map((cell, index) => {
          const tdClassNames = classnames("rt-td", cell.column.className, {
            "is-in-odd-row": rowIndex % 2 === 0,
          });

          return (
            <div {...cell.getCellProps({ className: tdClassNames, style: columnStyle(cell.column) })}>
              {cell.render("Cell", { index: rowIndex })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface LeaderBoardTableProps {
  page: number;
  totalCount: number;
  tableData: {
    topFive: LeaderboardPlayerPlus[];
    remainingData: LeaderboardPlayerPlus[];
    firstEntryRanking: number;
    lastEntryRanking: number;
  };
  hidePagination?: boolean;
  showRegion?: boolean;
}

const LeaderboardTable = (props: LeaderBoardTableProps) => {
  const { getProfileIconImg } = getRiotAssetsContext();
  const { page, totalCount, tableData, hidePagination, showRegion } = props;
  const [windowBreakpoint] = useGlobal("responsive");
  const queryParams = useQueryString();
  const { user } = queryParams;

  const { remainingData, firstEntryRanking, lastEntryRanking } = tableData || {};
  const isMobileSmall = ["MOBILE_MEDIUM", "MOBILE_SMALL"].includes(windowBreakpoint);
  const isMobile = ["MOBILE_LARGE", "MOBILE_MEDIUM", "MOBILE_SMALL"].includes(windowBreakpoint);

  const columns = useMemo<Column<LeaderboardPlayerPlus>[]>(() => {
    const getRankingFontSize = (ranking: number) => {
      let rankingFontSize = 14;
      if (isMobileSmall) {
        rankingFontSize = ranking > 99999 ? 10 : ranking > 999 ? 11 : 12;
      }
      return rankingFontSize;
    };
    return [
      {
        id: "rank",
        accessor: "overallRanking",
        Header: !isMobileSmall ? "Rank" : "",
        maxWidth: isMobile ? 60 : 100,
        Cell: ({ cell: { value } }) => (
          <span className="ranking" style={{ fontSize: getRankingFontSize(value) }}>
            {numberWithCommas(value)}
          </span>
        ),
      },
      {
        accessor: "overallRanking",
        headerClassName: "summoner-col",
        className: "summoner-col",
        Header: "Summoner",
        minWidth: isMobileSmall ? 60 : 180,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Link className="summoner" to={original.profileURL}>
            <div className="profile-icon-container">
              <img src={getProfileIconImg(original.iconId || 1)} />
            </div>
            <div className="summoner-name">{original.riotUserName}</div>
          </Link>
        ),
      },
      {
        id: "region",
        accessor: "regionId",
        headerClassName: "region",
        className: "region",
        Header: "Region",
        minWidth: isMobileSmall ? 60 : 100,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <div className="region">
            {original.regionId && REGION_LABEL_MAP[original.regionId] ? REGION_LABEL_MAP[original.regionId] : ""}
          </div>
        ),
      },
      {
        id: "tier-lp_desktop",
        accessor: "lp",
        headerClassName: "tier-lp_desktop",
        className: "tier-lp_desktop",
        Header: "Tier",
        maxWidth: 180,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <div className="tier">
            <img className="rank-img" src={getS13RankUrl(original.tier, true)} />
            <strong style={{ color: getTierColor(original.tier) }}>{getProBuildRankName(original.tier, original.rank)}</strong>
          </div>
        ),
      },
      {
        headerClassName: "tier-lp_desktop",
        className: "tier-lp_desktop",
        accessor: "lp",
        Header: "LP",
        maxWidth: 170,
        Cell: ({ cell: { value } }) => <span>{numberWithCommas(value || 0)} LP</span>,
      },
      {
        // Tier and LP for mobile
        id: "tier-lp",
        accessor: "lp",
        headerClassName: "tier-lp_mobile",
        className: "tier-lp_mobile",
        minWidth: isMobileSmall ? 80 : 140,
        Header: () => (
          <span>
            Tier <span className="backslash">/</span> LP
          </span>
        ),
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <div className="tier">
            <img className="rank-img" src={getS13RankUrl(original.tier, true)} />
            <span>
              <strong style={{ color: getTierColor(original.tier) }}>
                {getProBuildRankName(original.tier, original.rank, true)}
              </strong>
              <span className="backslash"> / </span>
              <span>{numberWithCommas(original.lp || 0)} LP</span>
            </span>
          </div>
        ),
      },
      {
        id: "winRate",
        accessor: "overallRanking",
        Header: "Win Rate",
        minWidth: isMobileSmall ? 50 : 110,
        maxWidth: windowBreakpoint === "MOBILE_SMALL" ? 60 : isMobileSmall ? 80 : 220,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <div className="win-rate">
            <div className="wr-text">
              <div className="wr-text_win-rate" style={{ color: original.winRateColor }}>
                {original.winRate}%
              </div>
              <div className="wr-text_games">
                {numberWithCommas(original.wins)}W {numberWithCommas(original.losses)}L
              </div>
            </div>
            <div className="wr-bar">
              <div style={{ height: "100%", width: `${original.winRate}%`, backgroundColor: original.winRateColor }} />
            </div>
          </div>
        ),
      },
    ];
  }, [tableData, windowBreakpoint]);

  const {
    state,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page: tablePage,
    rows,
    prepareRow,
    setHiddenColumns,
    setPageSize,
  } = useTable(
    {
      columns,
      data: remainingData,
      initialState: {
        pageSize: 100,
        hiddenColumns: [!showRegion ? "region" : ""],
      },
    },
    usePagination,
  );

  return (
    <div {...getTableProps({ className: "content-section ReactTable ugg-table-2 leaderboard_table" })}>
      <div className="rt-thead -header">
        {headerGroups.map((headerGroup, index) => (
          <div {...headerGroup.getHeaderGroupProps({ className: "rt-tr" })}>
            {headerGroup.headers.map((column, index) => {
              const thClassNames = classnames(["rt-th", column.headerClassName]);

              return (
                <div {...column.getHeaderProps({ className: thClassNames, style: columnStyle(column) })}>
                  {column.render("Header")}
                </div>
              );
            })}
          </div>
        ))}
      </div>
      <div {...getTableBodyProps({ className: "rt-tbody" })}>
        {tablePage.map((row, i) => {
          prepareRow(row);
          const [searchedRiotUserName, searchedRiotTagLine] = splitRiotId(user || "");
          const rowSummoner = { riotUserName: row.original.riotUserName, riotTagLine: row.original.riotTagLine };
          const isSearchedSummoner =
            searchedRiotUserName.toLowerCase() === rowSummoner.riotUserName.toLowerCase() &&
            searchedRiotTagLine.toLowerCase() === rowSummoner.riotTagLine.toLowerCase();

          return (
            <TableSummonerRow
              key={rowSummoner.riotUserName + rowSummoner.riotTagLine}
              rowIndex={i}
              row={row}
              isSearchedSummoner={isSearchedSummoner}
            />
          );
        })}
      </div>
      <LeaderboardPagination
        firstEntryRanking={firstEntryRanking}
        lastEntryRanking={lastEntryRanking}
        totalCount={totalCount}
        currentPage={page}
        hidePagination={hidePagination}
      />
    </div>
  );
};

export default LeaderboardTable;
